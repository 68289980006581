import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import { getCartList } from "@/core/helpers/custom";
export default defineComponent({
    name: "login",
    components: {
        Form: Form,
    },
    setup: function () {
        var _this = this;
        var store = useStore();
        var loading = ref({
            login: false,
            guest: false,
        });
        var route = useRoute();
        var router = useRouter();
        var isFromCheckout = ref(false);
        var formLoginValidate = Yup.object().shape({
            email: Yup.string().email("Invalid").required("Required").label("Email"),
        });
        var onSubmitLogin = function (values) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.login = true;
                        return [4 /*yield*/, store
                                .dispatch(Actions.LOGIN, values)
                                .then(function () {
                                if (isFromCheckout.value) {
                                    router.push({ name: "checkout" });
                                }
                                else {
                                    router.push({ name: "home" });
                                }
                            })
                                .catch(function () {
                                ("_");
                            })];
                    case 1:
                        _a.sent();
                        loading.value.login = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var gotoMobileLogin = function () {
            loading.value.guest = true;
            router.push({ name: "mobile-login" }).then(function () {
                loading.value.guest = false;
            });
        };
        onMounted(function () {
            isFromCheckout.value = route.params.gotoCheckout === "true";
            getCartList();
        });
        return {
            formLoginValidate: formLoginValidate,
            onSubmitLogin: onSubmitLogin,
            loading: loading,
            gotoMobileLogin: gotoMobileLogin,
        };
    },
});
